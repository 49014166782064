import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Pagination from "react-js-pagination";
import Spinner from "react-spinner-material";
import styled from "styled-components";
import { CaretRightOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import Config from '../config/dev';
const configURL = Config.configURL;

const ATreeLV1 = styled.a`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
`;
const ATreeLV2 = styled.a`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 30px;
  margin-bottom: 5px;
`;
const ATreeLV3 = styled.a`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 50px;
  margin-bottom: 5px;
`;
const ATreeLV4 = styled.a`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 70px;
  margin-bottom: 5px;
`;
const ATreeLV5 = styled.a`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 90px;
  margin-bottom: 5px;
`;
const ATreeLV6 = styled.a`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 110px;
  margin-bottom: 5px;
`;
const ATreeLV7 = styled.a`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 130px;
  margin-bottom: 5px;
`;
const ATreeLV8 = styled.a`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 160px;
  margin-bottom: 5px;
`;
const ATreeLV9 = styled.a`
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 190px;
  margin-bottom: 5px;
`;

export default class csv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            data: [],
            per_page: 15,
            total: 0,
            lastPage: 0,
            loadPage: [],
            loading: true,
            valueSearch: "",

            level1ID: '',
            level2ID: '',
            level3ID: '',
            level4ID: '',
            level5ID: '',
            statID: '',
            refenceID: '',
            templateID: '',
            selectTemID: '',
            listLevelMaster: [],
            listLevel1: [],
            listLevel2: [],
            listLevel3: [],
            listLevel4: [],
            listStat: [],
            listRefence: [],
            listTemplate: [],
            listTemButton: [],
            loadingPopupStatus: false,
            nameCSV: ''
        }
        this.handleChange1 = this.handleChange1.bind(this);
    }
    async componentDidMount() {
        this.handleQuearyMaster();
    }
    async handleQuearyMaster() {
        try {
            axios({
                method: 'post',
                url: configURL + '/api/exportdata/exportsdmx/iframe',
                data: {}
            }).then(async (res) => {
                this.setState({ listLevelMaster: res.data.category });
            }).catch((err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }
    async handleQuearyLevel1(level1) {
        try {
            this.setState({
                // level1ID: '',
                level2ID: '',
                level3ID: '',
                level4ID: '',
                level5ID: '',
                statID: '',
                refenceID: '',
                templateID: '',
                selectTemID: '',
                listLevel1: [],
                listLevel2: [],
                listLevel3: [],
                listLevel4: [],
                listStat: [],
                listRefence: [],
                listTemplate: [],
                listTemButton: [],
                nameCSV: ''
            });
            axios({
                method: 'post',
                url: configURL + '/api/exportdata/exportsdmx/iframe',
                data: { LEVEL1: level1 }
            }).then(async (res) => {
                this.setState({ listLevel1: res.data.category });
                if (res.data.statlist !== '') {
                    this.setState({ listStat: res.data.statlist });

                    this.quearyStat(level1);
                } else {
                    this.setState({ listStat: [] });
                }
            }).catch((err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }
    async handleQuearyLevel2(level2) {
        try {
            this.setState({
                // level1ID: '',
                // level2ID: '',
                level3ID: '',
                level4ID: '',
                level5ID: '',
                statID: '',
                refenceID: '',
                templateID: '',
                selectTemID: '',
                // listLevel1: [],
                listLevel2: [],
                listLevel3: [],
                listLevel4: [],
                listStat: [],
                listRefence: [],
                listTemplate: [],
                listTemButton: [],
                nameCSV: ''
            });
            axios({
                method: 'post',
                url: configURL + '/api/exportdata/exportsdmx/iframe',
                data: {
                    LEVEL1: this.state.level1ID,
                    LEVEL2: level2
                }
            }).then(async (res) => {
                this.setState({ listLevel2: res.data.category });
                if (res.data.statlist !== '') {
                    this.setState({ listStat: res.data.statlist });

                    this.quearyStat(level2);
                } else {
                    this.setState({ listStat: [] });
                }
            }).catch((err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }
    async handleQuearyLevel3(level3) {
        try {
            this.setState({
                // level1ID: '',
                // level2ID: '',
                // level3ID: '',
                level4ID: '',
                level5ID: '',
                statID: '',
                refenceID: '',
                templateID: '',
                selectTemID: '',
                // listLevel1: [],
                // listLevel2: [],
                listLevel3: [],
                listLevel4: [],
                listStat: [],
                listRefence: [],
                listTemplate: [],
                listTemButton: [],
                nameCSV: ''
            });
            axios({
                method: 'post',
                url: configURL + '/api/exportdata/exportsdmx/iframe',
                data: {
                    LEVEL1: this.state.level1ID,
                    LEVEL2: this.state.level2ID,
                    LEVEL3: level3
                }
            }).then(async (res) => {
                this.setState({ listLevel3: res.data.category });
                if (res.data.statlist !== '') {
                    this.setState({ listStat: res.data.statlist });

                    this.quearyStat(level3);
                } else {
                    this.setState({ listStat: [] });
                }
            }).catch((err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }
    async handleQuearyLevel4(level4) {
        try {
            this.setState({
                // level1ID: '',
                // level2ID: '',
                // level3ID: '',
                // level4ID: '',
                level5ID: '',
                statID: '',
                refenceID: '',
                templateID: '',
                selectTemID: '',
                // listLevel1: [],
                // listLevel2: [],
                // listLevel3: [],
                listLevel4: [],
                listStat: [],
                listRefence: [],
                listTemplate: [],
                listTemButton: [],
                nameCSV: ''
            });
            axios({
                method: 'post',
                url: configURL + '/api/exportdata/exportsdmx/iframe',
                data: {
                    LEVEL1: this.state.level1ID,
                    LEVEL2: this.state.level2ID,
                    LEVEL3: this.state.level3ID,
                    LEVEL4: level4
                }
            }).then(async (res) => {
                this.setState({ listLevel4: res.data.category });
                if (res.data.statlist !== '') {
                    this.setState({ listStat: res.data.statlist });

                    this.quearyStat(level4);
                } else {
                    this.setState({ listStat: [] });
                }
            }).catch((err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }
    async handleQuearyLevel5(level5) {
        try {
            this.setState({
                // level1ID: '',
                // level2ID: '',
                // level3ID: '',
                // level4ID: '',
                // level5ID: '',
                statID: '',
                refenceID: '',
                templateID: '',
                selectTemID: '',
                // listLevel1: [],
                // listLevel2: [],
                // listLevel3: [],
                // listLevel4: [],
                listStat: [],
                listRefence: [],
                listTemplate: [],
                listTemButton: [],
                nameCSV: ''
            });
            axios({
                method: 'post',
                url: configURL + '/api/exportdata/exportsdmx/iframe',
                data: {
                    LEVEL1: this.state.level1ID,
                    LEVEL2: this.state.level2ID,
                    LEVEL3: this.state.level3ID,
                    LEVEL4: this.state.level4ID,
                    LEVEL5: level5
                }
            }).then(async (res) => {
                this.setState({ listStat: res.data.statlist });
            }).catch((err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }
    async quearyStat(stat) {
        try {
            this.setState({
                // level1ID: '',
                // level2ID: '',
                // level3ID: '',
                // level4ID: '',
                // level5ID: '',
                // statID: '',
                refenceID: '',
                templateID: '',
                selectTemID: '',
                // listLevel1: [],
                // listLevel2: [],
                // listLevel3: [],
                // listLevel4: [],
                // listStat: [],
                listRefence: [],
                listTemplate: [],
                listTemButton: [],
                nameCSV: ''
            });
            axios({
                method: 'post',
                url: configURL + '/api/exportdata/exportsdmx/iframe',
                data: {
                    LEVEL1: this.state.level1ID,
                    LEVEL2: this.state.level2ID,
                    LEVEL3: this.state.level3ID,
                    LEVEL4: this.state.level4ID,
                    LEVEL5: this.state.level5ID,
                    STATLIST: stat
                }
            }).then(async (res) => {
                this.setState({ listRefence: res.data.referen });
            }).catch((err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }
    async quearyReference(ref) {
        try {
            this.setState({
                // level1ID: '',
                // level2ID: '',
                // level3ID: '',
                // level4ID: '',
                // level5ID: '',
                // statID: '',
                // refenceID: '',
                templateID: '',
                selectTemID: '',
                // listLevel1: [],
                // listLevel2: [],
                // listLevel3: [],
                // listLevel4: [],
                // listStat: [],
                // listRefence: [],
                listTemplate: [],
                listTemButton: [],
                nameCSV: ''
            });
            axios({
                method: 'post',
                url: configURL + '/api/exportdata/exportsdmx/iframe',
                data: {
                    LEVEL1: this.state.level1ID,
                    LEVEL2: this.state.level2ID,
                    LEVEL3: this.state.level3ID,
                    LEVEL4: this.state.level4ID,
                    LEVEL5: this.state.level5ID,
                    STATLIST: this.state.statID,
                    REFEREN: ref
                }
            }).then(async (res) => {
                await this.setState({ listTemplate: res.data.template });
            }).catch((err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }
    async quearyTemplate(temp) {
        try {
            this.setState({
                // level1ID: '',
                // level2ID: '',
                // level3ID: '',
                // level4ID: '',
                // level5ID: '',
                // statID: '',
                // refenceID: '',
                // templateID: '',
                selectTemID: '',
                // listLevel1: [],
                // listLevel2: [],
                // listLevel3: [],
                // listLevel4: [],
                // listStat: [],
                // listRefence: [],
                // listTemplate: [],
                listTemButton: [],
                nameCSV: ''
            });
            axios({
                method: 'post',
                url: configURL + '/api/exportdata/exportsdmx/iframe',
                data: {
                    LEVEL1: this.state.level1ID,
                    LEVEL2: this.state.level2ID,
                    LEVEL3: this.state.level3ID,
                    LEVEL4: this.state.level4ID,
                    LEVEL5: this.state.level5ID,
                    STATLIST: this.state.statID,
                    REFEREN: this.state.refenceID,
                    TEM: temp
                }
            }).then(async (res) => {
                axios({
                    method: 'get',
                    url: configURL + '/api/publicdownload/search?per_data=1&type=csv/' + this.state.templateID
                }).then((res1)=>{
                    if (res1.data.data.length !== 0) {
                        this.setState({
                            listTemButton: res.data.template,
                            nameCSV: res.data.template[0].TEMPLATE_NAME
                        });
                    } else {
                        this.setState({
                            listTemButton: [],
                            nameCSV: []
                        });
                        Swal.fire({
                            allowOutsideClick: false, icon: 'error',
                            title: 'ข้อมูลที่ท่านเลือกยังไม่ได้ทำการนำข้อมูลส่งออก CSV',
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                }).catch((err)=>{
                    console.error(err);
                });
            }).catch((err) => {
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }
    }
    loadingPopupStatus() {
        if (this.state.loadingPopupStatus) {
            Swal.fire({
                title: 'Processing... Please wait...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            })
        } else {
            Swal.close()
        }
    }
    async handleChange1() {
        let list = [];
        var text = ''
        axios({
            method: 'get',
            url: configURL + '/api/publicdownload/csvsearch?search=' + this.state.valueSearch,
        }).then((res) => {
            if (res.data.length !== 0) {
                res.data.map((data) => {
                    text = data.RESULT.split(",");
                    list.push(text);
                    text.map((val, index) => {
                        // console.log(val,index)
                        if (index === 0 && val !== null) {
                            // console.log(1)
                            this.setState({ level1ID: val });
                            this.handleQuearyLevel1(val)
                        }
                        if (index === 1 && val !== null) {
                            // console.log(2)
                            this.setState({ level2ID: val });
                            this.handleQuearyLevel2(val);
                        }
                        if(index === 2 && val !== null) {
                            // console.log(3)
                            this.setState({ level3ID: val });
                            this.handleQuearyLevel3(val);
                        }
                        if(index === 3 && val !== null) {
                            // console.log(4)
                            this.setState({ level4ID: val });
                            this.handleQuearyLevel4(val);
                        }
                        if(index === 4 && val !== null) {
                            // console.log(5)
                            this.setState({ level5ID: val });
                            this.handleQuearyLevel5(val);
                        }
                        if(index === 5 && val !== null) {
                            // console.log(6)
                            this.setState({ statID: val })
                            this.quearyStat(val);
                        }
                    })
                });
            } else {
                Swal.fire({
                    allowOutsideClick: false, icon: 'error',
                    title: 'ไม่พบข้อมูลที่ค้นหา',
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((err) => {
            console.error(err);
        });
    }
    async exportCSV() {
        try {
            this.setState({ loadingPopupStatus: true });
            this.loadingPopupStatus();
            axios({
                method: 'get',
                url: configURL + '/api/publicdownload/search?per_data=1&type=csv/' + this.state.templateID
            }).then(async (res) => {
                axios({
                    method: 'get',
                    url: res.data.data[0].URL,
                    responseType: 'blob',
                }).then((response) => {
                    this.setState({ loadingPopupStatus: false });
                    this.loadingPopupStatus();
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', res.data.data[0].STAT_LIST + '.' + 'csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
                this.setState({
                    level1ID: '',
                    level2ID: '',
                    level3ID: '',
                    level4ID: '',
                    level5ID: '',
                    statID: '',
                    refenceID: '',
                    templateID: '',
                    selectTemID: '',
                    listLevel1: [],
                    listLevel2: [],
                    listLevel3: [],
                    listLevel4: [],
                    listStat: [],
                    listRefence: [],
                    listTemplate: [],
                    listTemButton: [],
                    loadingPopupStatus: false,
                    nameCSV: '',
                    valueSearch: ''
                });
            }).catch((err) => {
                console.error(err);
                Swal.fire({
                    allowOutsideClick: false, icon: 'error',
                    title: 'ทำรายการไม่สำเร็จ \n' + err,
                    showConfirmButton: false,
                    timer: 2000
                });
            });
        } catch (err) {
            console.error(err);
        }
    }
    render() {
        const { listLevelMaster, valueSearch } = this.state;
        return (
            <div className="container" style={{ 'paddingTop': '10px', 'paddingBottom': '10px' }}>
                <div className="card">
                    <br />
                    <div className="row">
                        <div className="col-sm-7"></div>
                        <div className="col-sm-5 text-right">
                            <div className="form-group row">
                                <label className="col-form-label form-control-label">
                                    Search:
                                     </label>
                                <div className="col-lg-9">
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" value={this.state.valueSearch} onChange={(event) => { this.setState({ valueSearch: event.target.value }) }} />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button"
                                                onClick={this.handleChange1.bind(this)}>ค้นหา <i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        {/* ระดับแรก */}
                        {listLevelMaster.map((level1, index1) => {
                            return (
                                <div key={level1.ID}>
                                    <ATreeLV1 href='javascript:;' onClick="event.preventDefault();"
                                        style={{ color: `${this.state.level1ID === level1.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                        onClick={() => {
                                            this.setState({ level1ID: level1.ID });
                                            this.handleQuearyLevel1(level1.ID)
                                        }}>
                                        <CaretRightOutlined style={{ fontSize: '18px' }} />
                                        <span> {level1.CATEGORIZATION_NAME}</span>
                                    </ATreeLV1>

                                    {/* ระดับที่ 1 */}
                                    {this.state.level1ID === level1.ID ?
                                        this.state.listLevel1.map((level2, index2) => {
                                            return (
                                                <div key={level2.ID}>
                                                    <ATreeLV2 href='javascript:;' onClick="event.preventDefault();"
                                                        style={{ color: `${this.state.level2ID === level2.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                        onClick={() => {
                                                            this.setState({ level2ID: level2.ID });
                                                            this.handleQuearyLevel2(level2.ID)
                                                        }}>
                                                        <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                        <span> {level2.CATE_NAME}({level2.CATE_ID})</span>
                                                    </ATreeLV2>

                                                    {/* หน่วยงานระดับที่ 1 */}
                                                    {this.state.level2ID === level2.ID ?
                                                        this.state.listStat.map((stat2) => {
                                                            if (stat2.CATE_ID === level2.ID) {
                                                                return (
                                                                    <div key={stat2.ID}>
                                                                        <ATreeLV3 href='javascript:;' onClick="event.preventDefault();"
                                                                            style={{ color: `${this.state.statID === stat2.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                            onClick={() => {
                                                                                this.setState({ statID: stat2.ID })
                                                                                this.quearyStat(stat2.ID)
                                                                            }}>
                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                            <span> {stat2.STAT_LIST_NAME}({stat2.STAT_LIST_ID})</span>
                                                                        </ATreeLV3>

                                                                        {/* รายการข้อมูลระดับที่ 1 */}
                                                                        {this.state.statID === stat2.ID ?
                                                                            this.state.listRefence.map((ref2) => {
                                                                                return (
                                                                                    <div key={ref2.ID}>
                                                                                        <ATreeLV4 href='javascript:;' onClick="event.preventDefault();"
                                                                                            style={{ color: `${this.state.refenceID === ref2.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                            onClick={() => {
                                                                                                this.setState({ refenceID: ref2.ID })
                                                                                                this.quearyReference(ref2.ID)
                                                                                            }}>
                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                            <span> {ref2.AGAENCY_ID}({ref2.META_SOURCE})</span>
                                                                                        </ATreeLV4>

                                                                                        {/* รายการเทมเพลตระดับที่ 1 */}
                                                                                        {this.state.refenceID === ref2.ID ?
                                                                                            this.state.listTemplate.map((temp2) => {
                                                                                                return (
                                                                                                    <div key={temp2.ID}>
                                                                                                        <ATreeLV5 href='javascript:;' onClick="event.preventDefault();"
                                                                                                            style={{ color: `${this.state.templateID === temp2.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                            onClick={() => {
                                                                                                                this.setState({ templateID: temp2.ID })
                                                                                                                this.quearyTemplate(temp2.ID)
                                                                                                            }}>
                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                            <span> {temp2.TEMPLATE_NAME}</span>
                                                                                                        </ATreeLV5>

                                                                                                        {/* ดาวน์โหลดระดับ 1 */}
                                                                                                        {this.state.templateID === temp2.ID ?
                                                                                                            this.state.listTemButton.map((tem2) => {
                                                                                                                return (
                                                                                                                    <div key={tem2.ID}>
                                                                                                                        <ATreeLV6
                                                                                                                            style={{ color: `#007bff`, display: 'flex', alignItems: 'center' }}>
                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                            <button className="btn btn-success btn-sm"
                                                                                                                                onClick={this.exportCSV.bind(this)}>Download CSV</button>
                                                                                                                        </ATreeLV6>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                            : ''}
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            : ''}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            : ''}
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        : ''}

                                                    {/* ระดับที่ 2 */}
                                                    {this.state.level2ID === level2.ID ?
                                                        this.state.listLevel2.map((level3, index3) => {
                                                            return (
                                                                <div key={level3.ID}>
                                                                    <ATreeLV3 href='javascript:;' onClick="event.preventDefault();"
                                                                        style={{ color: `${this.state.level3ID === level3.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                        onClick={() => {
                                                                            this.setState({ level3ID: level3.ID });
                                                                            this.handleQuearyLevel3(level3.ID)
                                                                        }}>
                                                                        <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                        <span> {level3.CATE_NAME}({level3.CATE_ID})</span>
                                                                    </ATreeLV3>

                                                                    {/* หน่วยงานระดับที่ 2 */}
                                                                    {this.state.level3ID === level3.ID ?
                                                                        this.state.listStat.map((stat3) => {
                                                                            if (stat3.CATE_ID === level3.ID) {
                                                                                return (
                                                                                    <div key={stat3.ID}>
                                                                                        <ATreeLV4 href='javascript:;' onClick="event.preventDefault();"
                                                                                            style={{ color: `${this.state.statID === stat3.ID ? '' : 'black'}`, color: `${this.state.statID === stat3.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                            onClick={() => {
                                                                                                this.setState({ statID: stat3.ID })
                                                                                                this.quearyStat(stat3.ID)
                                                                                            }}>
                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                            <span> {stat3.STAT_LIST_NAME}({stat3.STAT_LIST_ID})</span>
                                                                                        </ATreeLV4>

                                                                                        {/* รายการข้อมูลระดับที่ 2 */}
                                                                                        {this.state.statID === stat3.ID ?
                                                                                            this.state.listRefence.map((ref3) => {
                                                                                                return (
                                                                                                    <div key={ref3.ID}>
                                                                                                        <ATreeLV5 href='javascript:;' onClick="event.preventDefault();"
                                                                                                            style={{ color: `${this.state.refenceID === ref3.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                            onClick={() => {
                                                                                                                this.setState({ refenceID: ref3.ID })
                                                                                                                this.quearyReference(ref3.ID)
                                                                                                            }}>
                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                            <span> {ref3.AGAENCY_ID}({ref3.META_SOURCE})</span>
                                                                                                        </ATreeLV5>

                                                                                                        {/* รายการเทมเพลตระดับที่ 2 */}
                                                                                                        {this.state.refenceID === ref3.ID ?
                                                                                                            this.state.listTemplate.map((temp3) => {
                                                                                                                return (
                                                                                                                    <div key={temp3.ID}>
                                                                                                                        <ATreeLV6 href='javascript:;' onClick="event.preventDefault();"
                                                                                                                            style={{ color: `${this.state.templateID === temp3.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                                            onClick={() => {
                                                                                                                                this.setState({ templateID: temp3.ID })
                                                                                                                                this.quearyTemplate(temp3.ID)
                                                                                                                            }}>
                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                            <span> {temp3.TEMPLATE_NAME}</span>
                                                                                                                        </ATreeLV6>

                                                                                                                        {/* ดาวน์โหลดระดับ 2 */}
                                                                                                                        {this.state.templateID === temp3.ID ?
                                                                                                                            this.state.listTemButton.map((tem3) => {
                                                                                                                                return (
                                                                                                                                    <div key={tem3.ID}>
                                                                                                                                        <ATreeLV7
                                                                                                                                            style={{ color: `#007bff`, display: 'flex', alignItems: 'center' }}>
                                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                                            <button className="btn btn-success btn-sm"
                                                                                                                                                onClick={this.exportCSV.bind(this)}>Download CSV</button>
                                                                                                                                        </ATreeLV7>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            })
                                                                                                                            : ''}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                            : ''}
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            : ''}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })
                                                                        : ''}

                                                                    {/* ระดับที่ 3 */}
                                                                    {this.state.level3ID === level3.ID ?
                                                                        this.state.listLevel3.map((level4, index4) => {
                                                                            return (
                                                                                <div key={level4.ID}>
                                                                                    <ATreeLV4 href='javascript:;' onClick="event.preventDefault();"
                                                                                        style={{ color: `${this.state.level4ID === level4.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                        onClick={() => {
                                                                                            this.setState({ level4ID: level4.ID });
                                                                                            this.handleQuearyLevel4(level4.ID)
                                                                                        }}>
                                                                                        <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                        <span> {level4.CATE_NAME}({level4.CATE_ID})</span>
                                                                                    </ATreeLV4>

                                                                                    {/* หน่วยงานระดับที่ 3 */}
                                                                                    {this.state.level4ID === level4.ID ?
                                                                                        this.state.listStat.map((stat4) => {
                                                                                            if (stat4.CATE_ID === level4.ID) {
                                                                                                return (
                                                                                                    <div key={stat4.ID}>
                                                                                                        <ATreeLV5 href='javascript:;' onClick="event.preventDefault();"
                                                                                                            style={{ color: `${this.state.statID === stat4.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                            onClick={() => {
                                                                                                                this.setState({ statID: stat4.ID })
                                                                                                                this.quearyStat(stat4.ID)
                                                                                                            }}>
                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                            <span> {stat4.STAT_LIST_NAME}({stat4.STAT_LIST_ID})</span>
                                                                                                        </ATreeLV5>

                                                                                                        {/* รายการข้อมูลระดับที่ 3 */}
                                                                                                        {this.state.statID === stat4.ID ?
                                                                                                            this.state.listRefence.map((ref4) => {
                                                                                                                return (
                                                                                                                    <div key={ref4.ID}>
                                                                                                                        <ATreeLV6 href='javascript:;' onClick="event.preventDefault();"
                                                                                                                            style={{ color: `${this.state.refenceID === ref4.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                                            onClick={() => {
                                                                                                                                this.setState({ refenceID: ref4.ID })
                                                                                                                                this.quearyReference(ref4.ID)
                                                                                                                            }}>
                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                            <span> {ref4.AGAENCY_ID}({ref4.META_SOURCE})</span>
                                                                                                                        </ATreeLV6>

                                                                                                                        {/* รายการเทมเพลตระดับที่ 3 */}
                                                                                                                        {this.state.refenceID === ref4.ID ?
                                                                                                                            this.state.listTemplate.map((temp4) => {
                                                                                                                                return (
                                                                                                                                    <div key={temp4.ID}>
                                                                                                                                        <ATreeLV7 href='javascript:;' onClick="event.preventDefault();"
                                                                                                                                            style={{ color: `${this.state.templateID === temp4.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                                                            onClick={() => {
                                                                                                                                                this.setState({ templateID: temp4.ID })
                                                                                                                                                this.quearyTemplate(temp4.ID)
                                                                                                                                            }}>
                                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                                            <span> {temp4.TEMPLATE_NAME}</span>
                                                                                                                                        </ATreeLV7>

                                                                                                                                        {/* ดาวน์โหลดระดับ 3 */}
                                                                                                                                        {this.state.templateID === temp4.ID ?
                                                                                                                                            this.state.listTemButton.map((tem4) => {
                                                                                                                                                return (
                                                                                                                                                    <div key={tem4.ID}>
                                                                                                                                                        <ATreeLV8
                                                                                                                                                            style={{ color: `#007bff`, display: 'flex', alignItems: 'center' }}>
                                                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                                                            <button className="btn btn-success btn-sm"
                                                                                                                                                                onClick={this.exportCSV.bind(this)}>Download CSV</button>
                                                                                                                                                        </ATreeLV8>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            : ''}
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            })
                                                                                                                            : ''}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                            : ''}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                        : ''}

                                                                                    {/* ระดับที่ 4 */}
                                                                                    {this.state.level4ID === level4.ID ?
                                                                                        this.state.listLevel4.map((level5, index5) => {
                                                                                            return (
                                                                                                <div key={level5.ID}>
                                                                                                    <ATreeLV5 href='javascript:;' onClick="event.preventDefault();"
                                                                                                        style={{ color: `${this.state.level5ID === level5.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                        onClick={() => {
                                                                                                            this.setState({ level5ID: level5.ID });
                                                                                                            this.handleQuearyLevel5(level5.ID)
                                                                                                        }}>
                                                                                                        <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                        <span> {level5.CATE_NAME}({level5.CATE_ID})</span>
                                                                                                    </ATreeLV5>

                                                                                                    {/* รายการข้อมูลระดับที่ 4 */}
                                                                                                    {this.state.level5ID === level5.ID ?
                                                                                                        this.state.listStat.map((stat5) => {
                                                                                                            if (stat5.CATE_ID === level5.ID) {
                                                                                                                return (
                                                                                                                    <div key={stat5.ID}>
                                                                                                                        <ATreeLV6 href='javascript:;' onClick="event.preventDefault();"
                                                                                                                            style={{ color: `${this.state.statID === stat5.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                                            onClick={() => {
                                                                                                                                this.setState({ statID: stat5.ID })
                                                                                                                                this.quearyStat(stat5.ID)
                                                                                                                            }}>
                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                            <span> {stat5.STAT_LIST_NAME}({stat5.STAT_LIST_ID})</span>
                                                                                                                        </ATreeLV6>

                                                                                                                        {/* หน่วยงานระดับที่ 4 */}
                                                                                                                        {this.state.statID === stat5.ID ?
                                                                                                                            this.state.listRefence.map((ref5) => {
                                                                                                                                return (
                                                                                                                                    <div key={ref5.ID}>
                                                                                                                                        <ATreeLV7 href='javascript:;' onClick="event.preventDefault();"
                                                                                                                                            style={{ color: `${this.state.refenceID === ref5.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                                                            onClick={() => {
                                                                                                                                                this.setState({ refenceID: ref5.ID })
                                                                                                                                                this.quearyReference(ref5.ID)
                                                                                                                                            }}>
                                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                                            <span> {ref5.AGAENCY_ID}({ref5.META_SOURCE})</span>
                                                                                                                                        </ATreeLV7>

                                                                                                                                        {/* รายการเทมเพลตระดับที่ 4 */}
                                                                                                                                        {this.state.refenceID === ref5.ID ?
                                                                                                                                            this.state.listTemplate.map((temp5) => {
                                                                                                                                                return (
                                                                                                                                                    <div key={temp5.ID}>
                                                                                                                                                        <ATreeLV8 href='javascript:;' onClick="event.preventDefault();"
                                                                                                                                                            style={{ color: `${this.state.templateID === temp5.ID ? '' : 'black'}`, display: 'flex', alignItems: 'center' }}
                                                                                                                                                            onClick={() => {
                                                                                                                                                                this.setState({ templateID: temp5.ID })
                                                                                                                                                                this.quearyTemplate(temp5.ID)
                                                                                                                                                            }}>
                                                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                                                            <span> {temp5.TEMPLATE_NAME}</span>
                                                                                                                                                        </ATreeLV8>

                                                                                                                                                        {/* ดาวน์โหลดระดับ 4 */}
                                                                                                                                                        {this.state.templateID === temp5.ID ?
                                                                                                                                                            this.state.listTemButton.map((temp4) => {
                                                                                                                                                                return (
                                                                                                                                                                    <div key={temp4.ID}>
                                                                                                                                                                        <ATreeLV9
                                                                                                                                                                            style={{ color: `#007bff`, display: 'flex', alignItems: 'center' }}>
                                                                                                                                                                            <CaretRightOutlined style={{ fontSize: '18px' }} />
                                                                                                                                                                            <button className="btn btn-success btn-sm"
                                                                                                                                                                                onClick={this.exportCSV.bind(this)}>Download CSV</button>
                                                                                                                                                                        </ATreeLV9>
                                                                                                                                                                    </div>
                                                                                                                                                                )
                                                                                                                                                            })
                                                                                                                                                            : ''}
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            : ''}
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            })
                                                                                                                            : ''}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                        : ''}
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        : ''}
                                                                                </div>
                                                                            )
                                                                        })
                                                                        : ''}
                                                                </div>
                                                            )
                                                        })
                                                        : ''}
                                                </div>
                                            )
                                        })
                                        : ''}
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        )
    }
}
