let config = {
  
  // บน server interset
  // configURL: 'http://interset.ddns.net:50000/integrator',
  // configBasenameURL: '/integrator',

  // run integrator โดย php artisan serve
  // configURL: 'http://127.0.0.1:8000',
  // configBasenameURL: '/',
  
  // // run integrator โดย serve จาก xampp
  // configURL: 'http://localhost/integrator/public',
  // configBasenameURL: '/integrator/public',

  // บน server nso
  configURL: 'https://gis.nso.go.th/integrator',
  configBasenameURL: '/integrator',
};

export default Object.freeze(Object.assign({}, config));
  