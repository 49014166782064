import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Pagination from "react-js-pagination";
import Spinner from "react-spinner-material";
import styled from "styled-components";
import { CaretRightOutlined } from '@ant-design/icons';
import Config from '../config/dev';
import Swal from 'sweetalert2';

const configURL = Config.configURL;


export default class csvnew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            data: [],
            per_page: 15,
            total: 0,
            lastPage: 0,
            loadPage: [],
            loading: true,
            valueSearch: "",
            valueSearchCheck: "",
            loadingPopupStatus: false
        }
    }
    async componentDidMount() {
        this.getData();
    }
    loadingPopupStatus() {
        if (this.state.loadingPopupStatus) {
            Swal.fire({
                title: 'ระบบกำลังทำงาน... โปรดรอสักครู่',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            })
        } else {
            Swal.close()
        }
    }
    async getData() {
        try {
            console.log('data to getData iframe /csv >>>',)
            console.log('per_data >>>', this.state.per_page)
            console.log('page >>>', this.state.activePage)
            console.log('Search >>>', this.state.valueSearch)
            const response = await axios({
                method: 'post',
                url: configURL + `/api/exportdata/exportsdmx/iframe2?per_data=${this.state.per_page}&page=${this.state.activePage}&Search=${this.state.valueSearch}`
            });
            console.log('response getData iframe /csv >>>', response)
            let data = [];
            var totalData = this.state.data;
            let pages = [];
            pages = this.state.loadPage;
            pages.push(this.state.activePage);
            var loadPage = this.state.data.find(el => {
                return el.pageNumber === this.state.activePage;
            });
            if (!loadPage) {
                response.data.data.map((datas, i) => {
                    data.push(datas);
                });
                totalData.push({
                    pageNumber: this.state.activePage,
                    data: data
                });
            }
            await this.setState({
                per_page: parseInt(response.data.per_page),
                data: totalData,
                total: response.data.total,
                lastPage: response.data.lastPage,
                loadPage: pages,
                loading: false,
                valueSearchCheck: this.state.valueSearch
            });
        } catch (error) {
            console.error(error);
        }
    }
    async handlePageChange(pageNumber) {
        await this.setState({
            activePage: pageNumber
        });
        var loadPage = this.state.loadPage.find(el => {
            return el === pageNumber;
        });
        if (!loadPage) {
            await this.setState({
                loading: true
            });
            await this.getData();
        }
    }
    async handleSearch() {
        if (this.state.valueSearch !== this.state.valueSearchCheck) {
            await this.setState({
                activePage: 1,
                loading: true,
                data: [],
                loadPage: [],
                total: 0,
                lastPage: 0,
            });
        }
        var loadPage = this.state.data.find(el => {
            return el.pageNumber === this.state.activePage;
        });
        if (!loadPage) {
            await this.getData();
        }
        await this.setState({
            loading: false
        });
    }
    
    // เผื่อได้เอา Download กลับมา
    async exportCSV() {
        try {
            await this.setState({ loadingPopupStatus: true });
            this.loadingPopupStatus();
            axios({
                method: 'get',
                url: configURL + '/api/publicdownload/search?per_data=1&type=csv/' + this.state.templateID
            }).then(async (res) => {
                console.log('response exportCSV iframe /csv >>>', res)
                axios({
                    method: 'get',
                    url: res.data.data[0].URL,
                    responseType: 'blob',
                }).then((response) => {
                    this.setState({ loadingPopupStatus: false });
                    this.loadingPopupStatus();
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', res.data.data[0].STAT_LIST + '.' + 'csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }).catch((err) => {
                    console.error(err);
                    this.setState({ loadingPopupStatus: false, })
                    Swal.fire({
                        allowOutsideClick: false, icon: 'error',
                        title: 'ทำรายการไม่สำเร็จ \n' + err,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });
            }).catch((err) => {
                console.error(err);
                this.setState({ loadingPopupStatus: false, })
                Swal.fire({
                    allowOutsideClick: false, icon: 'error',
                    title: 'ทำรายการไม่สำเร็จ \n' + err,
                    showConfirmButton: false,
                    timer: 2000
                });
            });
        } catch (err) {
            console.error(err);
        }
    }
    render() {
        let data = [];
        if (!this.state.loading) {
            {
                this.state.data.map((datas, index) => {
                    if (datas.pageNumber == this.state.activePage) {
                        datas.data.map((item, index) => {
                            data.push(
                                <tr key={index}>
                                    <td>
                                        {parseInt([(this.state.activePage - 1) * this.state.per_page]) + (index + 1)}.
                                        {item.STAT_LIST_NAME != null ? item.STAT_LIST_NAME : '-'}
                                    </td>
                                    <td>{item.STAT_LIST_ID_TRUE != null ? item.STAT_LIST_ID_TRUE : '-'}</td>
                                    <td>{item.NAME_AREA != null ? item.NAME_AREA : '-'}</td>
                                    <td>{item.VALID_FROM != null ? item.VALID_FROM : '-'} ถึง {item.VALID_TO != null ? item.VALID_TO : '-'}</td>
                                    <td>{item.AG_NAME != null ? item.AG_NAME : '-'}</td>
                                    {/* <td>
                                        <button className="btn btn-success btn-sm"
                                            onClick={async () => {
                                                await this.setState({ templateID: item.ID_TEM })
                                                this.exportCSV()
                                            }}>Download CSV</button>
                                    </td> */}
                                </tr>
                            );
                        });
                    }
                });
            }
        }
        return (
            <div className="container-fluid" id="App">
                <div className="card mb-3">
                    <div className="card-body">
                        {/* sesrch */}
                        <div className="row">
                            <div className="col-sm-7"></div>
                            <div className="col-sm-5 text-right">
                                <div className="form-group row">
                                    <label className="col-form-label form-control-label">
                                        Search:
                                     </label>
                                    <div className="col-lg-9">
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control" value={this.state.valueSearch} onChange={(event) => { this.setState({ valueSearch: event.target.value }) }} />
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" type="button"
                                                    onClick={() => { this.handleSearch() }}>ค้นหา <i class="fas fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* table */}
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>รายการข้อมูล</th>
                                        <th>รหัสรายการ</th>
                                        <th>ระดับข้อมูล</th>
                                        <th>ช่วงเวลา</th>
                                        <th>หน่วยงานเจ้าของข้อมูล</th>
                                        {/* <th>นำออก</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data}
                                </tbody>
                            </Table>
                            {this.state.loading ? (
                                <div style={{ display: "flex", justifyContent: "center" }}     >
                                    <Spinner radius={120} color={"#EE940A"} stroke={2} visible={true} />
                                </div>
                            ) : null}
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.per_page}
                                totalItemsCount={this.state.total}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                                firstPageText={"หน้าแรก"}
                                lastPageText={"หน้าสุดท้าย"}
                                itemClass={"page-item"}
                                linkClass={"page-link"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
