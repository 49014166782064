import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
// import './index.css';
import { Route, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Sdmx from "./sdmx/sdmx";
import Wms from "./wms/wms";
import Csv from "./csv/csv";
import Csvnew from "./csv/csvnew";

const routing = (
  <Router>
      {/* <Redirect from="/" to="/wms"/> */}
      <Route path="/sdmx" component={Sdmx} />
      <Route path="/wms" component={Wms} />
      <Route path="/listLevel" component={Csv} />
      <Route path="/csv" component={Csvnew} />
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'));

// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root')
// );

