import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Pagination from "react-js-pagination";
import Spinner from "react-spinner-material";
import Config from '../config/dev';
const configURL = Config.configURL;

export default class wms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            data: [],
            per_page: 15,
            total: 0,
            lastPage: 0,
            loadPage: [],
            loading: true,
            valueSearch: ""
        }
    }
    async componentDidMount() {
        this.getData();
    }
    async SelectPerpage(event) {
        await this.setState({
            per_page: event.target.value,
            activePage: 1,
            loadPage: [],
            data: [],
            loading: true
        });
        try {
            const response = await axios({
                method: 'get',
                url: configURL + `/api/publicdownload/search?per_data=${this.state.per_page}&type=wms`
            });
            let data = [];
            var totalData = [];
            let pages = [];
            pages = this.state.loadPage;
            pages.push(this.state.activePage);
            response.data.data.map((datas, i) => {
                data.push(datas);
            });
            totalData.push({
                pageNumber: this.state.activePage,
                data: data
            });
            await this.setState({
                per_page: response.data.per_page,
                data: totalData,
                total: response.data.total,
                lastPage: response.data.lastPage,
                loading: false
            });
        } catch (error) {
            console.error(error);
        }
    }
    async getDataSearch() {
        const response = await axios({
            method: 'get',
            url: configURL + `/api/publicdownload/search?per_data=${this.state.per_page}&page=${this.state.activePage}&search=${this.state.valueSearch}&type=wms`,
            config: { headers: { 'Content-Type': 'multipart/form-data', } },
        });
        await this.setState({
            activePage: 1,
            loading: true
        });
        var data = [];
        var totalData = [];
        let pages = [];
        pages = [];
        pages.push(this.state.activePage);
        response.data.data.map((datas, i) => {
            data.push(datas);
        });
        totalData.push({
            pageNumber: this.state.activePage,
            data: data
        });
        await this.setState({
            per_page: response.data.per_page,
            data: totalData,
            total: response.data.total,
            loading: false,
            loadPage: pages
        });
    }
    async getData() {
        try {
            const response = await axios({
                method: 'get',
                url: configURL + `/api/publicdownload/search?per_data=${this.state.per_page}&page=${this.state.activePage}&type=wms`
            });
            let data = [];
            var totalData = this.state.data;
            let pages = [];
            pages = this.state.loadPage;
            pages.push(this.state.activePage);
            var loadPage = this.state.data.find(el => {
                return el.pageNumber === this.state.activePage;
            });
            if (!loadPage) {
                response.data.data.map((datas, i) => {
                    data.push(datas);
                });
                totalData.push({
                    pageNumber: this.state.activePage,
                    data: data
                });
            }
            await this.setState({
                per_page: response.data.per_page,
                data: totalData,
                total: response.data.total,
                lastPage: response.data.lastPage,
                loadPage: pages,
                loading: false
            });
        } catch (error) {
            console.error(error);
        }
    }
    async handlePageChange(pageNumber) {
        await this.setState({
            activePage: pageNumber
        });
        var loadPage = this.state.loadPage.find(el => {
            return el === pageNumber;
        });
        if (!loadPage) {
            await this.setState({
                loading: true
            });
            await this.getData();
        }
    }
    async handleChange1(e) {
        await this.setState({
            valueSearch: e.target.value,
            activePage: 1,
            loading: true,
            data: [],
            loadPage: []
        });
        var loadPage = this.state.data.find(el => {
            return el.pageNumber === this.state.activePage;
        });
        if (!loadPage) {
            if (this.state.valueSearch) {
                await this.getDataSearch();
            } else {
                await this.getData();
            }
        }
        await this.setState({
            loading: false
        });
    }
    render() {
        let data = [];
        if (!this.state.loading) {
            {
                this.state.data.map((datas, index) => {
                    if (datas.pageNumber == this.state.activePage) {
                        datas.data.map((item, index) => {
                            data.push(
                                <tr key={item.ID}>
                                    <td>
                                        {parseInt([
                                            (this.state.activePage - 1) *
                                            this.state.per_page
                                        ]) +
                                            (index + 1)}. {item.STAT_LIST != null ? item.STAT_LIST : '-'}
                                    </td>
                                    <td>{item.URL != null ? <a href={item.URL} style={{display: 'block', width: '400px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{item.URL}</a> : '-'}</td>
                                    <td>{item.DESCRIPTION != null ? item.DESCRIPTION : '-'}</td>
                                </tr>
                            );
                        });
                    }
                });
            }
        }
        return (
            <div className="container-fluid" id="App">
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>รายการ WMS</th>
                                        <th>รูปแบบการเรียกใช้</th>
                                        <th>การนำไปใช้</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data}
                                </tbody>
                            </Table>
                            {this.state.loading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Spinner radius={120} color={"#EE940A"} stroke={2} visible={true} />
                                </div>
                            ) : null}
                        </div>
                        <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.per_page}
                                totalItemsCount={this.state.total}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                                firstPageText={"first"}
                                lastPageText={"Last"}
                                itemClass={"page-item"}
                                linkClass={"page-link"}
                            />
                    </div>
                </div>
            </div>
        );
    }
}
